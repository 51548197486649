<template>
  <div class="head-load-form seismic-form-options" style="padding-left: 20px;">
    <el-form class="el-form-custom" :model="formData" :inline="true">
      <el-form-item label="仿真类型">
        <el-select v-model="formData.typeGroupId" placeholder="请选择" @change="getTargetOptionsChange(formData.typeGroupId, false, main)">
          <el-option v-for="item in typeOptions" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="仿真结果">
        <el-select v-model="formData.typeId" placeholder="请选择" @change="getResultOptions">
          <el-option v-for="item in targetOptions" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="放大系数" v-show="!showBX || seismic">
        <el-input v-model="formData.enlargeFactor" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" @change="enlargeEnter" style="width: 80px; height: 32px"></el-input>
      </el-form-item>
      <el-form-item label="变形" v-show="showBX || seismic">
        <el-checkbox name="type" v-model="formData.checked" @change="distortionChange" :disabled="false"></el-checkbox>
      </el-form-item>
      <el-form-item label="变形系数" v-show="showBX || seismic">
        <el-input v-model="formData.distortionFactor" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" @change="inputEnter" style="width: 80px; height: 32px" :disabled="!formData.checked"></el-input>
      </el-form-item>
      <el-form-item label="截面">
        <el-checkbox name="type" v-model="formData.section" @change="sectionChange" :disabled="false"></el-checkbox>
      </el-form-item>
      <el-form-item label="播放时间" v-if="seismic">
        <el-input v-model="formData.frameFrequency" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" style="width: 80px; height: 32px" @change="inputPlay"></el-input>
      </el-form-item>
      <el-form-item v-if="playFlag == true">
        <template>
          <div class="bianxingitem">
            <i class="icon iconfont bx-play input-c pointer" :class="!searchForm.play ? 'icon-play_click_icon' : 'icon-pause_click_icon'" @click="playChange($event, 'bo')"></i>
            <el-input v-model="searchForm.currentCMLevel" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" size="mini" style="width: 128px" @change="levelPlay" @blur="levelPlay">
              <i slot="prefix" class="icon iconfont icon-houtui input-c pointer" @click="playOptionsChange('backward')"></i>
              <i slot="suffix" class="icon iconfont icon-qianjin input-c pointer" @click="playOptionsChange('forward')"></i>
            </el-input>
          </div>
        </template>
      </el-form-item>

      <el-form-item v-if="seismic">
        <div class="bianxingitem">
          <!--seismic  <span class="item-label" style="width: 60px">时间间隔：</span>
          <el-input v-model="searchForm.frameFrequency" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" size="mini" style="width: 64px" :disabled="!searchForm.value || !resultFlag" @change="inputPlay"></el-input> -->
          <!-- <el-checkbox v-model="searchForm.play" @change="playChange($event, 'bo')" style="margin-left:10px">播放</el-checkbox> -->
          <img :src="searchForm.play ? pause : play" @click="playChange($event, 'bo')" style="margin-left: 10px; cursor: pointer" />

          <!-- 开始帧 -->
          <img :src="start" @click="playOptionsChange('start')" style="margin-left: 15px; cursor: pointer; margin-right: 10px" />
          <!-- <img :src="forward" @click="playOptionsChange('forward')" style="cursor: pointer; margin-right: 10px" /> -->
          <el-input
            v-focus
            id="levelPlayRef"
            v-model="searchForm.currentCMLevel"
            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
            size="mini"
            style="width: 120px; margin: 0; transform: translateY(-3px)"
            :disabled="false"
            @change="levelPlay"
            @blur="levelPlay"
            v-if="searchForm.levelPlayFlag"
            @click="levelBlurPlay"
          ></el-input>
          <!--  && !CMLevel[0] && !CMLevel[0].length     /{{ CMLevel[0].length }} -->
          <span v-else class="level-input" @click="levelBlurPlay">
            {{ searchForm.currentCMLevel }}
            <span v-if="CMLevel && CMLevel.length">/{{ CMLevel.length - 1 }}</span>
          </span>
          <!-- <img :src="backward" @click="playOptionsChange('backward')" style="margin-left: 10px; cursor: pointer" /> -->
          <img :src="end" @click="playOptionsChange('end')" style="margin-left: 10px; cursor: pointer" />
        </div>
      </el-form-item>
    </el-form>

    <el-form class="el-form-custom" :model="formData" :inline="true" v-if="!shuohuang">
      <!-- <el-form-item>
        <el-button @click="getPavement('1')" class="btnStyle pointer" :class="{ 'active-bg': pavement == 1 }">左幅</el-button>
        <el-button @click="getPavement('2')" class="btnStyle pointer" :class="{ 'active-bg': pavement == 2 }">右幅</el-button>
      </el-form-item> -->
      <el-form-item label="截面位置">
        <el-select v-model="formData.positionGroupId" placeholder="请选择" @change="getPosition" class="sim-position" style="width: 210px">
          <el-option v-for="item in positionOptions" :key="item.point" :value="item.point" :label="item.name" />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="仿真节点">
        <el-select v-model="formData.positionId" placeholder="请选择" @change="getTargetPosition" class="sim-position" style="width: 100px">
          <el-option v-for="item in targetPositionOptions" :key="item.id" :value="item.name" :label="item.name" />
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item>
          <el-input v-model="formData.code" placeholder="请输入测点编号" />
        </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import { typeOptionsW } from "./mesh";

export default {
  props: {
    playFlag: {
      type: Boolean,
      default: false
    },
    deliveryId: {
      type: String,
      default: "-1"
    },
    shuohuang: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.querySelector("input").focus();
      }
    }
  },
  data() {
    return {
      seismic: "",
      formData: { enlargeFactor: "1", typeGroupId: "", frameFrequency: 0.5, section: true, typeId: "", code: "", positionGroupId: "", positionId: "", checked: false, distortionFactor: 200 },
      searchForm: { currentCMLevel: "1", play: false, value: "", levelPlayFlag: false },
      targetOptions: [],
      positionOptions: [],
      targetPositionOptions: [],
      pavement: "1",
      typeOptions: [],
      projectId: "",
      main: null,
      play: require("@/assets/img/zhw/play.png"),
      pause: require("@/assets/img/zhw/pause.png"),
      forward: require("@/assets/img/zhw/forward.png"),
      backward: require("@/assets/img/zhw/backward.png"),
      start: require("@/assets/img/zhw/start.png"),
      end: require("@/assets/img/zhw/end.png"),
      CMLevel: [],
      isGid: false,
      resultFlag: false,
      medasId: "",
      resItem: {},
      showArr: ["unoda0.u", "unoda0.v", "unoda0.w"],
      showBX: false,
      numFlag: true,
      time: null
    };
  },
  computed: {
    filterTypeOptions: {
      get() {
        console.log("computed=======================");
        if (this.deliveryId == "-1") {
          return typeOptionsW;
        } else {
          return typeOptionsW.filter((item) => item.id == this.deliveryId);
        }
      },
      set(v) {
        return v;
      }
    }
  },
  // watch: {
  //   deliveryId: {
  //     handler(n, o) {
  //       console.log("监听树n", n);
  //       // if (n == "-1") {
  //       //   this.typeOptions = typeOptionsW;
  //       // } else {
  //       //   this.typeOptions = typeOptionsW.filter((item) => item.id == n);
  //       // }
  //     }
  //   }
  // },
  // props: {
  //   deliveryId: {
  //     type: String,
  //     default: "-1"
  //   }
  //   // meshFlag: {
  //   //   type: Boolean,
  //   //   default: true
  //   // }
  // },
  mounted() {
    this.projectId = this.$store.state.projectId || sessionStorage.getItem("projectId");
    console.log("表单显示");
    
    // this.getSection();
  },
  methods: {
    enlargeEnter() {
      if (!this.main) return;

      //   输入放大系数
      console.log("放大系数", this.formData.enlargeFactor);
      this.main.CMs[0].setRateOfStr(this.formData.enlargeFactor);

      this.main.CMs[0].show();
      this.setUnit();
    },
    sectionChange() {
      // this.$emit("sectionChange", this.formData.section);
      console.log("点击切换medas", this.formData.section, this.main);
      // this.formData.section = !this.formData.section;
      this.main.CMs[1].setVisible(this.formData.section);
      console.log("点击切换medas", this.formData.section);
      // if(this.formData.section === true){
      //   console.log("点击切换medas","setMidasShowOnly")
      //   this.main.CMs[1].setMidasShowOnly(this.formData.section);
      // }

      // this.main.CMs[1].setMidasShowOnly(this.formData.section);

    
      // return;
      this.getResultOptions(this.medasId,true);
      this.distortionChange();
    },
    // 是否变形
    distortionChange() {
      // if(!this.main) return

      // this.$emit("distortionChange", this.formData.checked, this.formData.distortionFactor);
      //  参数 1、是否变形 2、boolean 变形系数
      console.log("变形", this.formData.section, this.formData.checked, this.formData.distortionFactor, this.main);
      this.main.CMs[0].setTransEnable(this.formData.checked);
      if (this.formData.checked) {
        // this.main.CMs[1].setTransRate(this.formData.distortionFactor);
        this.main.CMs[0].setTransRate(this.formData.distortionFactor);
      }

      if (this.formData.section) {
        // this.main.CMs[1].setTransRate(this.formData.distortionFactor);
        this.main.CMs[0].show();
        this.main.CMs[1].show();
        console.log("bianxing---show");
      } else {
        // if (this.formData.checked) {
        //   this.main.CMs[0].setTransRate(this.formData.distortionFactor);
        // }
        // // this.main.CMs[1].setTransEnable(this.formData.section);
        // this.main.CMs[0].setTransEnable(this.formData.checked);

        this.main.CMs[0].show();
        // this.main.CMs[1].show();
      }
      this.setUnit();  
    },
    levelPlay() {
      if (!this.main) return;

      this.main.CMs[0].show();
      this.main.CMs[1].show();
      console.log("失去焦点", this.searchForm.levelPlayFlag, this.searchForm.currentCMLevel);
      this.searchForm.currentCMLevel = parseInt(this.searchForm.currentCMLevel);
      if (this.searchForm.currentCMLevel) {
        this.main.CMs[0].setCurrentLevelByIndex(this.searchForm.currentCMLevel);
        this.main.CMs[1].setCurrentLevelByIndex(this.searchForm.currentCMLevel);
        // let arr = this.main.CMs[0].getCurrentCMLevel();
        // let arr = this.main.CMs[0].getSiteTimeList();

        // console.log("失去焦点数组", arr);
        // let len = arr[0].length;
        // for (let i = 0; i < len; i++) {
        //   if (this.searchForm.currentCMLevel == i) {
        //     let preL = this.main.getNextLevel(arr[0], arr[0][i]);
        //     this.main.CM.showStep(preL);
        //     break;
        //   }
        // }
      }
      this.searchForm.levelPlayFlag = false;
    },

    levelBlurPlay() {
      // if (!this.main) return;

      console.log("点击");
      this.searchForm.levelPlayFlag = !this.searchForm.levelPlayFlag;
    },
    playOptionsChange(flag) {
      if (!this.main) return;

      console.log("flag", flag, this.CMLevel);
      this.main.CMs[0].show();
      this.main.CMs[1].show();
      switch (flag) {
        case "start":
          // window.main1.CM.showStep(this.CMLevel[0][0]);
          // this.main.CM.showStep(this.CMLevel[0][0]);
          this.main.CMs[0].setCurrentLevelByIndex(1);
          this.main.CMs[1].setCurrentLevelByIndex(1);
          this.searchForm.currentCMLevel = 1;
          break;
        case "end":
          // window.main1.CM.showStep(this.CMLevel[0][this.CMLevel[0].length - 1]);
          // this.main.CM.showStep(this.CMLevel[0][this.CMLevel[0].length - 1]);

          let arr = this.main.CMs[1].getSiteTimeList() || this.main.CMs[0].getSiteTimeList();
          this.searchForm.currentCMLevel = arr.length - 1;
          this.main.CMs[1].setCurrentLevelByIndex(this.searchForm.currentCMLevel);
          this.main.CMs[0].setCurrentLevelByIndex(this.searchForm.currentCMLevel);

          break;
        case "forward":
          // let CMLevel1 = window.main1.CM.getCurrentCMLevel();
          let CMLevel1 = this.main.CMs[0].getCurrentLevelByIndex() || this.main.CMs[1].getCurrentLevelByIndex();
          this.searchForm.currentCMLevel = CMLevel1;

          let arr1 = this.main.CMs[1].getSiteTimeList() || this.main.CMs[0].getSiteTimeList();
          console.log("this.searchForm.currentCMLevel1111 ", this.searchForm.currentCMLevel, CMLevel1);

          if (CMLevel1 == 1) {
            this.searchForm.currentCMLevel = arr1.length - 1;
          } else {
            this.searchForm.currentCMLevel--;
          }
          console.log("this.searchForm.currentCMLevel22222 ", this.searchForm.currentCMLevel);

          this.main.CMs[1].setCurrentLevelByIndex(this.searchForm.currentCMLevel);
          this.main.CMs[0].setCurrentLevelByIndex(this.searchForm.currentCMLevel);
          console.log("this.searchForm.currentCMLevel ", this.searchForm.currentCMLevel);

          // if (CMLevel1[0][0] == CMLevel1[1]) {
          //   // window.main1.CM.showStep(CMLevel1[0][0]);
          //   // return;
          //   this.searchForm.currentCMLevel = this.CMLevel[0].length + 1;
          // }
          // this.searchForm.currentCMLevel--;
          // // let nextL = window.main1.getPreLevel(CMLevel1[0], CMLevel1[1]);
          // let nextL = this.main.getPreLevel(CMLevel1[0], CMLevel1[1]);
          // console.log("forward", nextL);
          // // window.main1.CM.showStep(nextL);
          // this.main.CM.showStep(nextL);
          break;
        case "backward":
          // let CMLevel2 = window.main1.CM.getCurrentCMLevel();
          // let CMLevel2 = this.main.CMs[0].getCurrentCMLevel();
          // if (CMLevel2[0][CMLevel2[0].length - 1] == CMLevel2[1]) {
          //   // window.main1.CM.showStep(CMLevel2[0][CMLevel2[0].length - 1]);
          //   // return;
          //   this.searchForm.currentCMLevel = 0;
          // }
          // this.searchForm.currentCMLevel++;
          // // let preL = window.main1.getNextLevel(CMLevel2[0], CMLevel2[1]);
          // let preL = this.main.getNextLevel(CMLevel2[0], CMLevel2[1]);
          // console.log("prel", preL);
          // // window.main1.CM.showStep(preL);
          // this.main.CM.showStep(preL);

          // let CMLevel1 = window.main1.CM.getCurrentCMLevel();
          let CMLevel11 = this.main.CMs[0].getCurrentLevelByIndex() || this.main.CMs[1].getCurrentLevelByIndex();
          this.searchForm.currentCMLevel = CMLevel11;
          let arr2 = this.main.CMs[1].getSiteTimeList() || this.main.CMs[0].getSiteTimeList();
          if (CMLevel11 == arr2.length - 1) {
            this.searchForm.currentCMLevel = 1;
          } else {
            this.searchForm.currentCMLevel++;
          }
          console.log("this.searchForm.currentCMLevel ", this.searchForm.currentCMLevel);
          this.main.CMs[1].setCurrentLevelByIndex(this.searchForm.currentCMLevel);
          this.main.CMs[0].setCurrentLevelByIndex(this.searchForm.currentCMLevel);

          break;
      }
    },

    playChange(e) {
      if (!this.main) return;

      console.log(e, "e", this.searchForm.frameFrequency);
      this.main.CMs[0].show();
      this.main.CMs[1].show();

      this.searchForm.play = !this.searchForm.play;

      // if (this.searchForm.play) {
      //   this.searchForm.frameFrequency = parseFloat(this.searchForm.frameFrequency);
      // }

      // this.$emit("playChange", this.searchForm.play, this.searchForm.frameFrequency);
      let that = this;
      if (this.searchForm.play) {
        console.log("true");
        this.main.CMs[0].play(this.formData.frameFrequency, function (res) {
          that.searchForm.currentCMLevel = res;
        });
        this.main.CMs[1].play(this.formData.frameFrequency, function (res) {
          that.searchForm.currentCMLevel = res;
        });
        this.time = setInterval(() => {
          this.searchForm.currentCMLevel = this.main.CMs[0].getCurrentCMLevel() || this.main.CMs[1].getCurrentCMLevel();
          this.$emit("getTargetPosition", this.targetPositionOptions[0]);
        }, 500);
        // this.searchForm.frameFrequency = parseFloat(this.searchForm.frameFrequency);
        // this.main.CMs[0].play(this.searchForm.frameFrequency);
        // let CMLevel2 = window.main1.CM.getCurrentCMLevel();
        // let preL = window.main1.getNextLevel(CMLevel2[0], CMLevel2[1]);
        // window.main1.CM.showStep(preL);
        //  this.searchForm.lineFrame = true;
      } else {
        console.log("false");
        // this.searchForm.currentCMLevelArr = window.main1.CM.getCurrentCMLevel();
        // this.searchForm.currentCMLevel = this.searchForm.currentCMLevelArr[1];
        this.main.CMs[0].stop();
        this.main.CMs[1].stop();
        this.searchForm.currentCMLevel = this.main.CMs[0].getCurrentCMLevel() || this.main.CMs[1].getCurrentCMLevel();
        // 暂停this.time
        clearInterval(this.time);
        // let arr = this.main.CMs[0].getCurrentCMLevel();

        // let len = arr[0].length;
        // for (let i = 0; i < len; i++) {
        //   if (arr[0][i] == arr[1]) {
        //     this.searchForm.currentCMLevel = i + 1;
        //     break;
        //   }
        // }
      }
      // console.log(" this.searchForm.play", this.searchForm.play);
      // this.main.CMs[0].show();
    },

    inputPlay() {
      console.log("searchForm.frameFrequency", this.searchForm.frameFrequency, this.searchForm.play);
      if (!this.main) return;
      if (this.searchForm.play) {
        this.searchForm.frameFrequency = parseFloat(this.searchForm.frameFrequency);
        // this.main.CM.stop();
        this.main.CMs[0].stop();
        this.main.CMs[1].stop();
        // this.main.CM.play(this.searchForm.frameFrequency);
        // this.main.CM.show();
        this.main.CMs[0].show();
        this.main.CMs[1].show();
        // this.$emit("inputPlay", this.searchForm.frameFrequency);

        this.main.CMs[0].play(this.formData.frameFrequency, function (res) {
          that.searchForm.currentCMLevel = res;
        });
        this.main.CMs[1].play(this.formData.frameFrequency, function (res) {
          that.searchForm.currentCMLevel = res;
        });
      }
    },

    // 变形系数
    inputEnter(e) {
      console.log("变形", this.formData.distortionFactor, "e", e);
      // this.$emit("inputEnter", e);
      this.distortionChange();
    },
    getPosition(e) {
      console.log("加载完成为切换", e);
      //   this.formData.positionGroupId = "";
      this.formData.positionId = "";
      // this.getPositionData(e);  接口逻辑变修改 2023.11.13zhw
      this.$emit("getTargetPosition", {name:e});
    },
    getTargetPosition(id) {
      // 给宋哥传仿真节点id
      console.log("仿真节点id选择", id);

      let item = this.targetPositionOptions.filter((t) => t.name == id);

      console.log("item", item);

      this.$emit("getTargetPosition", item[0]);
    },

    getPavement(pavement) {
      //   this.targetPositionOptions = [];
      //   this.formData.positionId = "";
      //   this.formData.positionGroupId = "";
      console.log("pavement", pavement);
      this.pavement = pavement;
      this.getSection();
    },
    getResultOptions(e,flag) {
      console.log(flag,"getResultOptions-e", e, this.formData.section, this.formData.checked, "this.showArr", this.showArr);
      // 第一次默认显示截面
      this.$emit("meshResultSelect", e, this.formData.typeGroupId);
      //   if (this.meshFlag) return;
      //   this.meshResultSelect(e);
      // this.$emit("meshResultSelect", e, this.formData.typeGroupId);
      this.medasId = e;

      if (this.showArr.includes(e)) {
        if (!this.showBX) {
          this.showBX = true;
        }
        if (this.formData.section) {
          this.main.CMs[0].setTransEnable(this.formData.checked);
          if (this.formData.checked) {
            // this.main.CMs[1].setTransRate(this.formData.distortionFactor);
            this.main.CMs[0].setTransRate(this.formData.distortionFactor);
          }
          this.main.CMs[1].show();
        }

        // this.main.CMs[1].show();
      } else {
        console.log("sssssssssssss=======");
        this.showBX = false;
        this.formData.checked = false;
        this.main.CMs[0].setTransEnable(false);
        this.main.CMs[0].show();
        console.log("sssssssssssss");
        // this.main.CMs[1].show();
      }

      if (this.formData.section) {
        setTimeout(() => {
          // this.main.CMs[0].updateColors(e);
          this.main.CMs[0].updateColors(e);
          
         this.setUnit();
          this.main.CMs[0].show();
          this.main.CMs[1].show();
        }, 50);
      } else {
        this.$emit("meshResultSelect", e, this.formData.typeGroupId);
      }
      setTimeout(() => {
       
        this.setUnit();
        
      }, 50);
      let pavement = this.pavement || "1";
      this.getPavement(pavement);
     
    },
    // 查询界面位置
    getSection() {
      // let params = {
      //   dataType: this.dataType,
      //   projectId: sessionStorage.getItem("projectId")
      // };

      // this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
      this.$axios.get(`${this.baseURL}simulation/section/2_3_1/${this.projectId}`).then((res) => {
      // this.$axios.get(`${this.baseURL}simulation/section/2_3_1/10034`).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("获取截面数据", res);
          this.positionOptions = res.data || [];
          if (this.positionOptions.length) {
            this.formData.positionGroupId = this.positionOptions[0].point;
            let arr=[]
            for(let i=0; i<this.positionOptions.length; i++){
              let reg={
                name:this.positionOptions[i].point

              }
              arr.push(reg)
            }
            this.targetPositionOptions=arr
            console.log('this.targetPositionOptions',this.targetPositionOptions)
            this.$emit("getTargetPosition", this.targetPositionOptions[0]);
            if (this.numFlag) {
            this.numFlag = false
            this.sectionChange()
          }

            // this.getPositionData(this.formData.positionGroupId); 接口逻辑变化，此方法已废弃 2023.11.13
          }
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 获取截面仿真节点接口逻辑更改





    // 获取截面仿真节点
    getPositionData(id) {
      let params = {
        sectionId: id,
        site: this.pavement
      };

      this.$axios.get(`${this.baseURL}simulation/points/${this.projectId}`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("获取截面节点数据111", res);
          this.targetPositionOptions = res.data || [];
          if (this.targetPositionOptions.length) {
            this.formData.positionId = this.targetPositionOptions[0].name;
            this.$emit("getTargetPosition", this.targetPositionOptions[0]);
          }
          console.log("获取截面节点数据111", this.numFlag);
          if (this.numFlag) {
            this.numFlag = false
            this.sectionChange()
          }
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },

    getTargetOptionsChange(e, flag = false, main) {
      console.log("云图加载完成回调", e, this.meshFlag, main);
      //   if (this.meshFlag) return;
      this.main = main;
      // this.seismic = seismic || "";
      // this.formData.section = flag;

      this.CMLevel = this.main.CMs[0].getSiteTimeList();
      this.formData.typeGroupId = e;
      // this.targetOptions = this.typeOptions[e - 1].data;
      this.targetOptions = typeOptionsW[e - 1].data;

      // this.formData.typeId = this.typeOptions[e - 1].data[0].id;
      this.formData.typeId = typeOptionsW[e - 1].data[0].id;
      console.log("返回数据11111=====", this.formData.typeId, this.targetOptions);
      this.medasId = this.formData.typeId;
      this.getResultOptions(this.formData.typeId);


      this.$emit("getTargetOptions", e);
      console.log("云图加载完成回调", this.main);

      this.sectionChange()
      // this.setUnit();
    },

    getTargetOptions(e, flag = false, main) {
      console.log("云图加载完成回调111", e, this.meshFlag, main);
      //   if (this.meshFlag) return;
      this.main = main;
      // this.seismic = seismic || "";
      // this.formData.section = flag;
      this.CMLevel = this.main.CMs[0].getSiteTimeList();
      if (e == "1") {
        this.typeOptions = typeOptionsW;
      } else {
        this.typeOptions = typeOptionsW.filter((item) => item.id == e);
      }

      this.formData.typeGroupId = e;
      // this.targetOptions = this.typeOptions[e - 1].data;
      this.targetOptions = typeOptionsW[e - 1].data;
      // this.formData.typeId = this.typeOptions[e - 1].data[0].id;
      this.formData.typeId = typeOptionsW[e - 1].data[0].id;
      console.log("返回数据11111=====", this.formData.typeId, this.targetOptions);
      this.medasId = this.formData.typeId;
      this.getResultOptions(this.formData.typeId);

      this.$emit("getTargetOptions", e);
      this.sectionChange()
    },
    setUnit() {
      let item = this.getResultItem();
      this.resItem = item;

      this.main.LUT.update_text("", this.resItem.unit);
    },
    getResultItem() {
      let n = this.targetOptions.length;
      for (let i = 0; i < n; i++) {
        if (this.targetOptions[i].id === this.medasId) {
          return this.targetOptions[i];
        }
      }
      return {};
    }
  },
  // 页面销毁清除this.time
  destroyed() {
    clearInterval(this.time);
  }
};
</script>
<style scoped lang="scss">
.head-load-form {
  // z-index: 50;
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 0;
  margin: 20px 0;
}
.head-load-form ::v-deep .el-input.is-disabled .el-input__inner {
  border-color: rgba(151, 151, 151, 0.23);
}
.head-load-form div.el-input.is-disabled ::v-deep .el-input__inner:hover {
  border-color: rgba(151, 151, 151, 0.23);
}
.pad26 {
  padding-left: 26px;
  padding-right: 26px;
  box-sizing: border-box;
}

.btnStyle {
  border: 1px solid #2667db;
  text-align: center;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0);
}
.btnStyle.el-button {
  padding-left: 23px;
  padding-right: 23px;
}
.active-bg {
  background-color: #2667db;
}

.pointer {
  cursor: pointer;
}

.el-form-custom .sim-position ::v-deep .el-input {
  width: 100% !important;
}

.el-form-custom .sim-position ::v-deep .el-input {
  width: 100% !important;
}

@media screen and (min-width: 1880px) {
  .el-form-custom .sim-position .el-input {
    width: 100% !important;
  }
}

.padr50 {
  padding-right: 50px !important;
  box-sizing: border-box;
}

.load-text {
  width: 810px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -405px;
  bottom: 50px;
}
.level-input {
  /* border: 1px solid #48b2ff;
  display: inline-block;
  width: 64px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #1a3357;
  border-radius: 4px; */

  color: #fff;
  border: 1px solid rgba(151, 151, 151, 0.23);
  background-color: rgba(0, 0, 0, 0.1) !important;
  font-weight: 300;
  border-radius: 20px;
  width: 120px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  display: inline-block;
}
.bianxingitem {
  // margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
}
.bx-play {
  height: 32px;
  padding: 0 16px;
  text-align: center;
  margin-right: 8px;
}
.input-c {
  color: #2772f0;
}

.seismic-form-options ::v-deep .el-form-custom .el-form-item__label {
  padding-left: 10px;
}
</style>
