// function scien(num) {
//   if (num === null || num === undefined) return 0;
//   let str = num.toString().trim();
//   if (str === "") {
//     return "0.0000 e+0";
//   }
//   if (str.indexOf("e") === -1) {
//     str = Number(str).toExponential().toString();
//   }
//   let arr = str.match(/^-?\d+\.\d{0,4}|e.+/gi);
//   return !Number(arr[0])?'':Number(arr[0]).toFixed(4) + arr[1]||'';
// }


function scien(scientificStr) {
  // 检查是否已经是科学计数法
  if (scientificStr.includes('e')) {
      const [coefficient, exponent] = scientificStr.split('e');
      const formattedCoefficient = parseFloat(coefficient).toFixed(4);
      return `${formattedCoefficient}e${exponent}`;
  } else {
      // 转换为科学计数法并保留四位小数
      const formattedValue = parseFloat(scientificStr).toExponential(4);
      return formattedValue;
  }
}

export { scien };
